import React, { useState } from "react";

import styled from "styled-components";
import lausanne from "./background.jpg";
import yt from "./yt.png";

import "./Intro.scss";
import "./bird.scss";

const Bird = () => (
  <div className="bird-container bird-container--one">
    <div className="bird bird--one"></div>
  </div>
);

const View = styled.div`
  text-align: center;
  height: 100vh;
  background-image: url(${lausanne});
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  background-size: cover;
`;

const Logo = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 123);
  // color: rgb(255, 255, 123);
  mix-blend-mode: difference;

  font-size: 5vw;
`;

export const Intro: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <div className="fade-in" >
      {visible && (
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            height: "100vh",
            width: "100vw",
            backgroundColor: "#000000d6",
            maxHeight: "100%",
            margin: "auto"
          }}
          className={visible && "fade-in"}
          onClick={() => setVisible(false)} 
        >
          <iframe
            width="70%"
            height="80%"
            style={{padding: "20px"}}
            title="Websites.city trailer"
            src="https://www.youtube.com/embed/2SGNuGSNU-Q?rel=0&modestbranding=1&autohide=1&showinfo=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <br />
          <span className="hover" onClick={() => setVisible(false)} style={{ color: "white" }}>
          Close
          </span>
        </div>
      )}
      <View />

      <Bird />

      <Logo>
        <h1>
          <span>Websites</span>
          <span>.city</span>
        </h1>
      </Logo>
      <div
        onClick={() => setVisible(true)}
        style={{
          position: "absolute",
          top: "75%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          fontFamily: "Consolas",
          fontSize: "1vw",
          height: "8vh",
        }}
      >
        TRAILER ON <br />
        <img
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          className="youtube hover"
          alt="Youtube"
          src={yt}
        />
      </div>
    </div>
  );
};
