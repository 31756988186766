import React, { useState } from "react";
import "./App.css";
import { Intro } from "./components/Intro/Intro";
import lausanne from "./components/Intro/background.jpg";

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div className="App" style={{ backgroundColor: "black", color: "white" }}>
      <img
        style={{ display: "none" }}
        src={lausanne}
        alt="loading..."
        onLoad={() => setLoading(false)}
      />

      {loading ? (
        <div className="hide-now fade-in">
          <div className="lds-dual-ring"></div>Loading...
        </div>
      ) : (
        <Intro />
      )}
    </div>
  );
};

export default App;
